<template>
  <div>
    <b-card title="Kullanılan Yağ">
      <b-row>
        <b-col>
          <b-form-group
            label="Başlık"
            label-for="title"
          >
            <b-form-input
              id="title"
              v-model="formData.title"
              placeholder="Başlık"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label-for="refractometer"
            label="Refraktometre Katsayısı"
          >
            <cleave
              id="refractometer"
              v-model="formData.refractometer"
              class="form-control"
              :raw="true"
              :options="options.floatVal"
              placeholder="Refraktometre Katsayısı (1,6)"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-button
      variant="danger"
      :disabled="submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </div>
</template>

<script>
import {
  BCard,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'

export default {
  name: 'UsedOilsAdd',
  components: {
    BCard,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    Cleave,
  },
  data() {
    return {
      submitStatus: false,
      formData: {
        title: null,
        refractometer: null,
      },
      options: {
        floatVal: {
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
    }
  },
  computed: {
    saveStatus() {
      const status = this.$store.getters['usedOils/getUsed_oilSaveStatus']
      return status
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        this.formData = {
          title: null,
        }
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: 'Kayıt işleminiz başarılı!',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: 'Kayıt işleminiz yapılamadı.! Lütfen tekrar deneyiniz.',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {

  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.submitStatus = true
      this.$store.dispatch('usedOils/used_oilSave', this.formData)
    },
  },
}
</script>
